import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private baseProjectStopURL? :string;

  constructor(private _http: HttpClient) {
    this.baseProjectStopURL = environment.apiUrl;
  }

  addUser(eGuildID: string, token: string, data: any): Observable<any> {
    const formData = new FormData();
    formData.append("idtype", "eg");
    formData.append("pharmacyId", eGuildID);
    formData.append("firstName", data.firstName);
    formData.append("lastName", data.lastName);
    formData.append("userName", data.userName);
    formData.append("email", data.searchText);

    return this.post(token, formData, "CreatePharmacyUser");
  }

  addClaimToUser(eGuildID: string, token: string, userNameOrEmail: string, role: string) {
    const formData = new FormData();
    formData.append("idtype", "eg");
    formData.append("pharmacyId", eGuildID);
    formData.append("usernameOrEmail", userNameOrEmail);
    formData.append("roles", role);

    return this.post(token, formData, "AddPhamacyClaimsToUser");
  }

  removeClaimFromUser(eGuildID: string, token: string, userNameOrEmail: string, roles: string[]) {
    const formData = new FormData();
    formData.append("idtype", "eg");
    formData.append("pharmacyId", eGuildID);
    formData.append("usernameOrEmail", userNameOrEmail);

    roles.forEach(function (item) {
      formData.append("roles", item);
    });

    return this.post(token, formData, "RemovePhamacyClaimsFromUser");
  }

  getUserList(eGuildID: string, token: string): Observable<any> {
    const params = new HttpParams()
      .set("idtype", "eg")
      .set("pharmacyId", eGuildID);
    return this.get(token, params, "GetPharmacyUsers");
  }

  getUserByUserNameOrEmail(token: string, userNameOrEmail: string) {
    const params = new HttpParams().set("usernameOrEmail", userNameOrEmail);
    return this.get(token, params, "GetPharmacyUserByUsernameOrEmail");
  }

  getUserFromUserData(data: any, eGuildID: string) {
    const user: any = {};
    user.firstName = data.firstName;
    user.lastName = data.lastName;
    user.userName = data.userName;
    user.email = data.email;
    user.role = "";
    for (let j = 0; j < data.claims.length; j++) {
      const claim = data.claims[j];
      if (claim.value == eGuildID) {
        if (claim.type.startsWith("ph-admin")) {
          user.role = "Admin";
          break;
        } else if (claim.type.startsWith("pharmacist")) {
          user.role = "Staff";

          // continue to search if this user is also an admin
          continue;
        }
      }
    }
    return user;
  }

  private getAuthHeaders(token: string) {
    return new HttpHeaders({
      Authorization: `Bearer ${token}`
    });
  }

  private get(token:string, params: HttpParams, endpoint: string) {
    const headers = this.getAuthHeaders(token);
    const options = { headers, params };
    const url = `${this.baseProjectStopURL}/${endpoint}`;
    return this._http.get<any[]>(url, options);
  }

  private post(token:string, formData: FormData, endpoint: string) {
    const headers = this.getAuthHeaders(token);
    const options = { headers };
    const url = `${this.baseProjectStopURL}/${endpoint}`;
    return this._http.post(url, formData, options);
  }
}
