import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() {
    // do nothing.
   }
  
  private eGuildIdKey = 'ma.eGuildId';
  private lastUpdatedKey = 'ma.lastupdated';
  private authTokenKey = 'ma.authToken';

  setEguildId(eGuildId: string)
  {
    if ((eGuildId !== null) && (eGuildId !== ''))
    {
      sessionStorage.setItem(this.eGuildIdKey, eGuildId);
      this.setLastUpdated("eGuildId");
    }
  }

  setAuthToken(authToken: string)
  {
    sessionStorage.setItem(this.authTokenKey, authToken);
    this.setLastUpdated("authToken");
  }

  getEguildId()
  {
    return sessionStorage.getItem(this.eGuildIdKey);
  }
  getAuthToken()
  {
    return sessionStorage.getItem(this.authTokenKey);
  }

  clearPharmacyData()
  {
    sessionStorage.removeItem(this.eGuildIdKey);
    sessionStorage.removeItem(this.authTokenKey);
  }

  private setLastUpdated(subject: string)
  {
    const dateTime = new Date();
    sessionStorage.setItem(this.lastUpdatedKey, `${subject} ${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`);
  }
}
