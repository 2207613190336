
<button class="close-button" mat-button [mat-dialog-close]="true">X</button>
<h1 mat-dialog-title>User</h1>
<form [formGroup]="userForm" (ngSubmit)="onSearchPatient()">
  <div mat-dialog-content class="content">
    <mat-form-field appearance="outline">
      <input type="text" matInput placeholder="Email address" formControlName="searchText" cdkFocusInitial>
      <button matSuffix mat-button><mat-icon >search</mat-icon></button>
    </mat-form-field>
  </div>
</form>
<div *ngIf="searchCompleted">
  <div *ngIf="userAssociated" mat-dialog-content class="content">
    <mat-label class="message">{{searchEmailOrUserName}} is already associated with the pharmacy</mat-label>
  </div>
  <div *ngIf="userAssociated === false" mat-dialog-content>
    <div class="message content">
      <mat-label *ngIf="userExisting">{{searchEmailOrUserName}} is an existing user</mat-label>
      <mat-label *ngIf="userExisting === false">User not found. Create new user with this email?</mat-label>
    </div>

    <form [formGroup]="userForm" (ngSubmit)="onFormSubmit()">
      <mat-grid-list cols="3" rowHeight="85px" [gutterSize]="'3px'">
        <!--User First Name/Last Name-->
        <mat-grid-tile>
          <mat-label class="field-name">Name</mat-label>
        </mat-grid-tile>
        <mat-grid-tile *ngIf="userExisting" [colspan]="2">
          <mat-label>{{existingNameOfUser}}</mat-label><br>
        </mat-grid-tile>
        <mat-grid-tile *ngIf="userExisting === false">
          <mat-form-field appearance="outline">
            <mat-label>FirstName</mat-label>
            <input matInput type="text" formControlName="firstName">
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile *ngIf="userExisting === false">
          <mat-form-field appearance="outline">
            <mat-label>LastName</mat-label>
            <input matInput type="text" formControlName="lastName">
          </mat-form-field>
        </mat-grid-tile>

        <!--User Name-->
        <mat-grid-tile>
          <mat-label class="field-name">User Name</mat-label>
        </mat-grid-tile>
        <mat-grid-tile *ngIf="userExisting" [colspan]="2">
          <mat-label>{{existingUserName}}</mat-label><br>
        </mat-grid-tile>
        <mat-grid-tile *ngIf="userExisting === false" [colspan]="2">
          <mat-form-field appearance="outline">
            <mat-label>Username</mat-label>
            <input matInput type="text" formControlName="userName">
          </mat-form-field>
        </mat-grid-tile>

        <!--Role-->
        <mat-grid-tile>
          <mat-label class="field-name">Role</mat-label>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="2">                
          <mat-form-field appearance="outline">
            <mat-label>Role</mat-label>
            <mat-select formControlName="role">
              <mat-option *ngFor="let userRole of userRoles" [value]="userRole">
                {{userRole}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
      <div mat-dialog-actions class="action">
        <button mat-raised-button color="primary" type="submit" [disabled]="userForm.invalid || userForm.pristine">Save</button>
      </div>
    </form>
  </div>
</div>

