import { Component, Inject, OnInit } from '@angular/core';
import { CoreService } from "../../core/core.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UserService } from "../../shared/user.service";
import { StorageService } from 'src/app/shared/storage.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  userForm: FormGroup;

  userRoles: string[] = [
    'Staff',
    'Admin'
  ];

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private storageService: StorageService,
    private dialogRef: MatDialogRef<UserEditComponent>,
    private coreService: CoreService,
    @Inject(MAT_DIALOG_DATA) public data: any //To get data during edit. Public to change icon
  ) {
    this.userForm = this.formBuilder.group ({
      firstName: '',
      lastName: '',
      email: '',
      userName: '',
      role: ''
    })
  }

  ngOnInit() {
    this.userForm.patchValue(this.data);

    // Only role field is editable
    this.userForm.controls['firstName'].disable();
    this.userForm.controls['lastName'].disable();
    this.userForm.controls['email'].disable();
    this.userForm.controls['userName'].disable();
  }

  onFormSubmit() {
    if(this.userForm.valid) {
      if(this.data) {
        const completeMessage = 'User Details Updated.';
        const failureMessage = 'Unable to Update User';
        const userData = this.userForm.getRawValue();

        if(this.data.role == 'Staff' && userData.role == 'Admin') {
          this.userService.addClaimToUser(
            this.storageService.getEguildId()!,
            this.storageService.getAuthToken()!,
            userData.email, "ph-admin").subscribe({
              next: (val: any) => {
                this.coreService.openSnackBar(completeMessage)
                this.dialogRef.close(true);
              },
              error: (err: any) => {
                this.coreService.openSnackBar(`${failureMessage} - ${err.error.message}`)
                console.error(err);
              },
          });
        } else if(this.data.role == 'Admin' && userData.role == 'Staff') {
          this.userService.removeClaimFromUser(
            this.storageService.getEguildId()!,
            this.storageService.getAuthToken()!,
            userData.email, ["ph-admin"]).subscribe({
              next: (val: any) => {
                this.coreService.openSnackBar(completeMessage)
                this.dialogRef.close(true);
              },
              error: (err: any) => {
                this.coreService.openSnackBar(`${failureMessage} - ${err.error.message}`)
                console.error(err);
              },
          });
        } else {
          // when the form is dirty but role is not changed, do nothing
          this.dialogRef.close(true);
        }
      } else {
        this.coreService.openSnackBar('User not found');
      }
    }
  }
}
