<div mat-dialog-title>
    <h1>User Edit</h1>
  </div>
  
  <form [formGroup]="userForm" (ngSubmit)="onFormSubmit()">
    <div mat-dialog-content class="content">
  
      <div class="row">
        <mat-form-field appearance="outline">
          <mat-label>First Name</mat-label>
          <input matInput type="text" formControlName="firstName">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Last Name</mat-label>
          <input matInput type="text" formControlName="lastName">
        </mat-form-field>
      </div>
  
      <div class="row">
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput type="email" formControlName="email">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Username</mat-label>
          <input matInput type="text" formControlName="userName">
        </mat-form-field>
      </div>
  
      <div class="row">
        <mat-form-field appearance="outline">
          <mat-label>Role</mat-label>
          <mat-select formControlName="role">
            <mat-option *ngFor="let userRole of userRoles" [value]="userRole">
              {{userRole}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
  
    </div>
    <div mat-dialog-actions class="action">
      <button mat-raised-button type="button" [mat-dialog-close]="false">Cancel</button>
      <button mat-raised-button color="primary" type="submit" [disabled]="userForm.invalid || userForm.pristine">Update</button>
    </div>
  </form>
  