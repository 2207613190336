import { Component,Inject } from '@angular/core';
import { UserService } from "../../shared/user.service";
import { CoreService } from "../../core/core.service";
import { MAT_DIALOG_DATA,MatDialogRef } from "@angular/material/dialog";
import { StorageService } from 'src/app/shared/storage.service';

@Component({
  selector: 'app-user-remove-confirmation',
  templateUrl: './user-remove-confirmation.component.html',
  styleUrls: ['./user-remove-confirmation.component.scss']
})
export class UserRemoveConfirmationComponent {

  constructor(
    private _userService: UserService,
    private _coreService: CoreService,
    private _storageService: StorageService,
    private dialogRef: MatDialogRef<UserRemoveConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      this.data = data;
    }

    removeUser(data: any) {
      this._userService.removeClaimFromUser(
        this._storageService.getEguildId()!,
        this._storageService.getAuthToken()!,
        data.email, ["ph-admin","pharmacist"]).subscribe({
          next: (res) =>{
            this._coreService.openSnackBar('User Removed','Done');
            this.dialogRef.close(true);
          },
          error: (err) => {
            this._coreService.openSnackBar(`${err.error.message}`);
            console.log(err);
          }
      });
    }

}
