<mat-toolbar color="primary">
  <span class="toolbar-spacer"></span>
  <span>{{eGuildId}}</span>
  <button mat-raised-button (click)="openUserForm()">
    ADD USER
  </button>
</mat-toolbar>

<div class="user-list-content">
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
        <td mat-cell *matCellDef="let row"> {{row.id}} </td>
      </ng-container>

      <!-- First Name Column -->
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
        <td mat-cell *matCellDef="let row"> {{row.firstName}} </td>
      </ng-container>

      <!-- Last Name Column -->
      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
        <td mat-cell *matCellDef="let row"> {{row.lastName}} </td>
      </ng-container>

      <!-- Username Column -->
      <ng-container matColumnDef="userName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Username </th>
        <td mat-cell *matCellDef="let row"> {{row.userName}} </td>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
        <td mat-cell *matCellDef="let row"> {{row.email}} </td>
      </ng-container>

      <!-- Role Column -->
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Role </th>
        <td mat-cell *matCellDef="let row"> {{row.role}} </td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
        <td mat-cell *matCellDef="let row">
          <button mat-icon-button color="primary" (click)="openUserEditForm(row)">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button color="warn" (click)="openRemoveConfirmationDialog(row)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
  </div>
</div>
