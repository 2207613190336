<div class="wrapper">
    <header class="header-section">
        <div class="title-container">
            <h1><b>MedAdvisor Identity App</b></h1>
        </div>
    </header>
    <div class="error-message">
        <p class="error-name">
            <img class="error-icon" alt="Error" style='width:30px;height:30px;' src='assets/images/icon_error.svg' />
            Authentication Error
        </p>
        <p>
            You are not authorised to access this area. Please contact your pharmacy admin for access.
        </p>
    </div> 
</div>