import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/shared/storage.service';
import { UserAddEditComponent } from "../user-add-edit/user-add-edit.component";
import { UserRemoveConfirmationComponent } from "../user-remove-confirmation/user-remove-confirmation.component";
import { UserService } from "../../shared/user.service";
import { UserEditComponent } from "../user-edit/user-edit.component";

declare function getEguildIdAsync(): any;
declare function getAuthTokenAsync(): any;

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit{

  displayedColumns: string[] = ['id', 'firstName', 'lastName', 'userName', 'email', 'role', 'action'];
  dataSource!: MatTableDataSource<any>;
  eGuildId ='';

  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private dialog: MatDialog,
    private userService: UserService,
    private storageService: StorageService,
    private router: Router) {}

  ngOnInit() {

    const resolveEguildId = new Promise<string> ((resolve) => {
      const value = (getEguildIdAsync());
      resolve(value);
    });

    resolveEguildId.then(value => {
      if (value)
      {
        this.storageService.setEguildId(value);
        this.eGuildId = value;
      }
    });

    const resolveAuthToken = new Promise<string> ((resolve) => {
      const value = (getAuthTokenAsync());
      resolve(value);
    });

    resolveAuthToken.then(value => {
      if (!value)
      {
        // Navigate to authentication error page if user is not authorised
        this.router.navigate(["/errorPage"]);
      }
      else
      {
        this.storageService.setAuthToken(value);
        this.getUserList();
      } 
    });
  }

  openUserForm() {
      const addDialogRef = this.dialog.open(UserAddEditComponent);
      addDialogRef.afterClosed().subscribe({
        next: (val) => {
          if(val) {
            this.getUserList();
          }
        }
      })
    }

  openUserEditForm(data: any) {
    const addDialogRef = this.dialog.open(UserEditComponent, {
      data: data,
    });
    addDialogRef.afterClosed().subscribe({
      next: (val) => {
        if(val) {
          this.getUserList();
        }
      }
    })
  }

  getUserList() {
    if (this.storageService.getEguildId() == null) {
      return;
    }

    if (this.storageService.getAuthToken() == null) {
      return;
    }

    this.userService.getUserList(
      this.storageService.getEguildId()!,
      this.storageService.getAuthToken()!).subscribe({
      next: (res) =>{
        console.log(res);
        if (res.result == "OK") {
          const source = [];
          for (let i = 0; i < res.data.length; i++) {
            const data = res.data[i];
            const user: any = this.userService.getUserFromUserData(data, this.storageService.getEguildId()!);
            user.id = i;
            source.push(user);
          }
          this.dataSource = new MatTableDataSource(source);
          this.dataSource.sort = this.sort;
        }
      },
      error: (err) => {
        console.log(err);
      }
    })
  }

  openRemoveConfirmationDialog(id:number) {
    const addDialogRef = this.dialog.open(UserRemoveConfirmationComponent, {
      data: id
    });
    addDialogRef.afterClosed().subscribe({
      next: (val) => {
        if(val) {
          this.getUserList();
        }
      }
    })
  }

}
