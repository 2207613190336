import { Component, OnInit } from '@angular/core';
import { CoreService } from "../../core/core.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { UserService } from "../../shared/user.service";
import { StorageService } from 'src/app/shared/storage.service';

@Component({
  selector: 'app-user-add-edit',
  templateUrl: './user-add-edit.component.html',
  styleUrls: ['./user-add-edit.component.scss']
})
export class UserAddEditComponent implements OnInit {

  userForm: FormGroup;
  searchCompleted = false;
  userExisting = false;
  userAssociated = false;
  searchEmailOrUserName = '';

  // Existing username and existing firstname/lastname of user
  existingUserName = '';
  existingNameOfUser = '';

  userRoles: string[] = [
    'Staff',
    'Admin'
  ];

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private storageService: StorageService,
    private dialogRef: MatDialogRef<UserAddEditComponent>,
    private coreService: CoreService
  ) {
    this.userForm = this.formBuilder.group ({
      firstName: '',
      lastName: '',
      userName: '',
      role: '',
      searchText: ''
    })
  }

  ngOnInit() {
    // This code is to make lint happy, it doesn't like empty functions.
    console.debug("Add User");
  }

  onSearchPatient() {
    const userData = this.userForm.getRawValue();
    if (userData.searchText == '') {
      this.coreService.openSnackBar("Please input user name or email!");
      return;
    }

    this.searchEmailOrUserName = userData.searchText;
    this.userService.getUserByUserNameOrEmail(
      this.storageService.getAuthToken()!,
      userData.searchText)
      .subscribe({next: (val: any) => {
        const data = this.userService.getUserFromUserData(val.data, this.storageService.getEguildId()!);
        this.existingUserName = data.userName;
        this.existingNameOfUser = `${data.firstName} ${data.lastName}`;
        this.userAssociated = data.role != "";
        this.userExisting = data.role == "";

        this.searchCompleted = true;
        return;
      },
      error: (err: any) => {
        this.searchCompleted = true;
        this.userAssociated = false;
        this.userExisting = false;
        return;
      }
    });
  }

  onFormSubmit() {
    if(this.userForm.valid) {
      if(this.userExisting) {
        const completeMessage = 'Associate User Complete';
        const failureMessage = 'Unable to Associate User';
        const userData = this.userForm.getRawValue();
        const role = userData.role == "Admin" ? "ph-admin" : "pharmacist";        
        this.userService.addClaimToUser(
          this.storageService.getEguildId()!,
          this.storageService.getAuthToken()!,
          userData.searchText, role).subscribe({
            next: (val: any) => {
              this.coreService.openSnackBar(completeMessage)
              this.dialogRef.close(true);
            },
            error: (err: any) => {
              this.coreService.openSnackBar(`${failureMessage} - ${err.error.message}`)
              console.error(err);
            },
          });
      } else {
        const completeMessage = 'Registration Complete';
        const failureMessage = 'Unable to Create User';
        const userData = this.userForm.getRawValue();
        this.userService.addUser(
          this.storageService.getEguildId()!,
          this.storageService.getAuthToken()!,
          userData).subscribe({
            next: (val: any) => {
              if (userData.role == 'Admin') {
                this.userService.addClaimToUser(
                  this.storageService.getEguildId()!,
                  this.storageService.getAuthToken()!,
                  userData.email, "ph-admin").subscribe({
                    next: (val: any) => {
                      this.coreService.openSnackBar(completeMessage)
                      this.dialogRef.close(true);
                    },
                    error: (err: any) => {
                      this.coreService.openSnackBar(`${failureMessage} - ${err.error.message}`)
                      console.error(err);
                    },
                });
              } else {
                this.coreService.openSnackBar(completeMessage)
                this.dialogRef.close(true);
              }
          },
          error: (err: any) => {
            this.coreService.openSnackBar(`${failureMessage} - ${err.error.message}`)
            console.error(err);
          },
        });
      }
    }
  }
}
