import { Component } from '@angular/core';

@Component({
  selector: 'app-authentication-error-page',
  templateUrl: './authentication-error-page.component.html',
  styleUrls: ['./authentication-error-page.component.scss']
})
export class AuthenticationErrorPageComponent {

}
